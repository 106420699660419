import { SVGProps } from "react";

export const LoadingSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 430.91406 559.70956"
    {...props}
  >
    <path
      d="M689.29823,324.68445q0,4.785-.31006,9.49a143.75442,143.75442,0,0,1-13.46973,52.19c-.06006.14-.13037.27-.18994.4-.36035.76-.73047,1.52-1.11035,2.27a142.03868,142.03868,0,0,1-7.6499,13.5,144.462,144.462,0,0,1-118.56006,66.72l1.43018,82.24,18.6499-9.82,3.33008,6.33-21.83985,11.5,2.66992,152.74.02979,2.04-14.41992,1.21.02978-.05,4.54-246.18a144.17482,144.17482,0,0,1-102-44.38c-.90967-.94-1.81006-1.91-2.68994-2.87-.04-.04-.06982-.08-.1001-.11a144.76758,144.76758,0,0,1-26.33984-40.76c.14014.16.29.31.43017.47a144.642,144.642,0,0,1,68.57959-186.38c.5-.25,1.01026-.49,1.51026-.74a144.75207,144.75207,0,0,1,187.52978,56.93c.88037,1.48005,1.73047,2.99006,2.5503,4.51A143.85218,143.85218,0,0,1,689.29823,324.68445Z"
      transform="translate(-384.54297 -170.14522)"
      fill="#e5e5e5"
    />
    <circle cx="198.2848" cy="502.61836" r="43.06733" fill="#2f2e41" />
    <rect
      x="210.6027"
      y="532.22265"
      width="38.58356"
      height="13.08374"
      fill="#2f2e41"
    />
    <ellipse
      cx="249.45884"
      cy="534.4033"
      rx="4.08868"
      ry="10.90314"
      fill="#2f2e41"
    />
    <rect
      x="201.6027"
      y="531.22265"
      width="38.58356"
      height="13.08374"
      fill="#2f2e41"
    />
    <ellipse
      cx="240.45884"
      cy="533.4033"
      rx="4.08868"
      ry="10.90314"
      fill="#2f2e41"
    />
    <path
      d="M541.051,632.71229c-3.47748-15.5738,7.63866-31.31043,24.82866-35.14881s33.94421,5.67511,37.42169,21.2489-7.91492,21.31769-25.10486,25.156S544.5285,648.28608,541.051,632.71229Z"
      transform="translate(-384.54297 -170.14522)"
      fill="#0f172a"
    />
    <path
      d="M599.38041,670.31119a10.75135,10.75135,0,0,1-10.33984-7.12305,1,1,0,0,1,1.896-.63672c1.51416,4.50782,6.69825,6.86524,11.55457,5.25342a9.60826,9.60826,0,0,0,5.57251-4.74756,8.23152,8.23152,0,0,0,.48547-6.33789,1,1,0,0,1,1.896-.63672,10.217,10.217,0,0,1-.59229,7.86817,11.62362,11.62362,0,0,1-6.73218,5.75244A11.87976,11.87976,0,0,1,599.38041,670.31119Z"
      transform="translate(-384.54297 -170.14522)"
      fill="#fff"
    />
    <path
      d="M618.56452,676.16463a9.57244,9.57244,0,1,1-17.04506,8.71737h0l-.00855-.01674c-2.40264-4.70921.91734-7.63227,5.62657-10.03485S616.162,671.45547,618.56452,676.16463Z"
      transform="translate(-384.54297 -170.14522)"
      fill="#fff"
    />
    <path
      d="M772.27559,716.2189h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z"
      transform="translate(-384.54297 -170.14522)"
      fill="#3f3d56"
    />
    <ellipse
      cx="567.22606"
      cy="706.64241"
      rx="7.50055"
      ry="23.89244"
      transform="translate(-543.03826 -6.10526) rotate(-14.4613)"
      fill="#2f2e41"
    />
    <path
      d="M645.50888,621.42349H629.12323a.77274.77274,0,0,1-.51881-1.3455l14.90017-13.49467h-13.7669a.77274.77274,0,0,1,0-1.54548h15.77119a.77275.77275,0,0,1,.51881,1.34551L631.12753,619.878h14.38135a.77274.77274,0,1,1,0,1.54548Z"
      transform="translate(-384.54297 -170.14522)"
      fill="#cbcbcb"
    />
    <path
      d="M666.37288,597.46853H649.98723a.77275.77275,0,0,1-.51881-1.34551l14.90017-13.49466h-13.7669a.77274.77274,0,0,1,0-1.54548h15.77119a.77274.77274,0,0,1,.51881,1.3455l-14.90016,13.49467h14.38135a.77274.77274,0,1,1,0,1.54548Z"
      transform="translate(-384.54297 -170.14522)"
      fill="#cbcbcb"
    />
    <path
      d="M657.1,571.19534H640.71434a.77274.77274,0,0,1-.51881-1.3455l14.90017-13.49467H641.3288a.77274.77274,0,0,1,0-1.54548H657.1a.77275.77275,0,0,1,.51881,1.34551l-14.90016,13.49466H657.1a.77274.77274,0,0,1,0,1.54548Z"
      transform="translate(-384.54297 -170.14522)"
      fill="#cbcbcb"
    />
    <path
      d="M770.66217,347.522,783.457,337.28854c-9.93976-1.09662-14.0238,4.32429-15.69525,8.615-7.76532-3.22446-16.21881,1.00136-16.21881,1.00136l25.6001,9.29375A19.37209,19.37209,0,0,0,770.66217,347.522Z"
      transform="translate(-384.54297 -170.14522)"
      fill="#3f3d56"
    />
    <path
      d="M403.66217,180.522,416.457,170.28854c-9.93976-1.09662-14.0238,4.32429-15.69525,8.615-7.76532-3.22446-16.21881,1.00136-16.21881,1.00136l25.6001,9.29375A19.37209,19.37209,0,0,0,403.66217,180.522Z"
      transform="translate(-384.54297 -170.14522)"
      fill="#3f3d56"
    />
    <path
      d="M802.66217,215.522,815.457,205.28854c-9.93976-1.09662-14.0238,4.32429-15.69525,8.615-7.76532-3.22446-16.21881,1.00136-16.21881,1.00136l25.6001,9.29375A19.37209,19.37209,0,0,0,802.66217,215.522Z"
      transform="translate(-384.54297 -170.14522)"
      fill="#3f3d56"
    />
  </svg>
);
